import React from 'react';
import { Testimonial } from '@/types';
import { graphql, useStaticQuery } from 'gatsby';
import SetecLogo from '@/assets/img/logos-clients/light-mode/setec.svg';
import SetecLogoDark from '@/assets/img/logos-clients/dark-mode/setec.svg';

export default function Setec(): Testimonial {
  const data = useStaticQuery(graphql`
    query {
      picture: file(relativePath: { eq: "projects/setec/setec-eocen.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return {
    fullName: 'Damien Daubricourt',
    job: 'testimonialJobSetec',
    company: 'Setec eocen',
    companyLogo: <SetecLogo />,
    companyLogoDark: <SetecLogoDark />,
    picture: data.picture,
    content: 'testimonialContentSetec',
    link: 'eocen.setec.com',
  };
}
